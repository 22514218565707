@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    min-height: 100%;
    width: 100%;
    height: 100%;
}

#waterfall {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.youtube-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.youtube-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
